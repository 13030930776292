import React, { Component } from 'react'
import Layout from '../components/layout'
import styled from 'styled-components'

const Wrapper = styled.div`
  background-color: #232323;
  display: flex;
  padding: 5% 0;
  flex-direction: column;
`
const Link = styled.button`
  font-size: 2.5vh;

  font-weight: bold;
  color: #ff9000;
  background-color: transparent;
  border: none;
`

const Heading = styled.h1`
  font-size: 8vh;
  font-weight: bold;
  padding: 0 5%;
  margin-bottom: 3%;
  color: white;
`

const SubHeading = styled.p`
  font-size: 2.5vh;
  font-weight: bold;
  padding: 0 5%;
  color: white;
`

const HeaderText = styled.h3`
  font-size: 3.5vh;
  font-weight: bold;
  padding: 0 5%;
  color: white;
`

const AnnexHeader = styled.h3`
  font-size: 3vh;
  font-weight: bold;
  padding: 0 5%;
  color: white;
`

const BodyText = styled.p`
  font-size: 2vh;
  padding: 0 5%;
  color: white;
`

const Bullet = styled.li`
  font-size: 1.8vh;
  padding: 0.5% 5%;
  color: white;
`

export default class privacy extends Component {
  render() {
    return (
      <Layout>
        <Wrapper>
          <Heading>Privacy Policy</Heading>
          <SubHeading>Document No. DP5A</SubHeading>
          <SubHeading>PRIVACY NOTICE</SubHeading>
          <SubHeading>Flexatal Limited Version: 001</SubHeading>
          <SubHeading style={{ marginBottom: '3%' }}>
            Dated: July 2019
          </SubHeading>
          <BodyText>
            The Company; Flexatal Limited (“Flexatal”) is a freelancer platform
            which provides work-finding services to its clients and
            work-seekers. Flexatal must process personal data (including
            sensitive personal data) so that it can provide these services – in
            doing so, Flexatal acts as a data controller.
          </BodyText>
          <BodyText>
            You may give your personal details to Flexatal directly, such as on
            an application or registration form or via our website, or we may
            collect them from another source such as a jobs board. Flexatal must
            have a legal basis for processing your personal data. For the
            purposes of providing you with work-finding services and/or
            information relating to roles relevant to you we will only use your
            personal data in accordance with the terms of the following
            statement.
          </BodyText>
          <HeaderText>1. Collection and use of personal data</HeaderText>
          <HeaderText>a. Purpose of processing and legal basis</HeaderText>
          <BodyText>
            Flexatal will collect your personal data (which may include
            sensitive personal data) and will process your personal data for the
            purposes of providing you with work-finding services. The legal
            bases we rely upon to offer these services to you are:
          </BodyText>

          <ul style={{ margin: '2% 6%' }}>
            <Bullet>Consent</Bullet>
            <Bullet>Legitimate Interest</Bullet>
            <Bullet>Legal Obligation</Bullet>
            <Bullet>Contractual Obligation</Bullet>
          </ul>
          <HeaderText>b. Legitimate interest</HeaderText>
          <BodyText>
            Where Flexatal has relied on a legitimate interest to process your
            personal data our legitimate interests is / are as follows:
          </BodyText>
          <ul style={{ margin: '2% 6%' }}>
            <Bullet>
              You have applied for an opportunity we have advertised.
            </Bullet>
          </ul>
          <HeaderText>c. Recipient/s of data</HeaderText>
          <BodyText>
            The Company will process your personal data and / or sensitive
            personal data with the following recipients:
          </BodyText>
          <ul style={{ margin: '2% 6%' }}>
            <Bullet>
              Clients and, should your application be successful, the following
              third parties:
            </Bullet>
            <Bullet>
              First Advantage (if applicable, should background checks be
              required by our client);
            </Bullet>
            <Bullet>
              ETZ (for the creation of contractual documents and, in the
              instance your placement is on a contract basis, processing of
              timesheets);
            </Bullet>
            <Bullet>
              Xero (for the processing of your invoices, in the instance your
              placement is on a contract basis);
            </Bullet>
            <Bullet>
              Barclays Bank (for the payment of your invoices, in the instance
              your placement is on a contract basis).
            </Bullet>
          </ul>
          <HeaderText>d. Statutory/contractual requirement</HeaderText>
          <BodyText>
            Your personal data is required by law and/or a contractual
            requirement (e.g. our client may require this personal data), and/or
            a requirement necessary to enter into a contract. You are obliged to
            provide the personal data and if you do not the consequences of
            failure to provide the data are:
          </BodyText>
          <Bullet>Withdrawal of an offer of employment from our client.</Bullet>
          <HeaderText>2. Overseas Transfers</HeaderText>
          <BodyText>
            The Company may transfer only the information you provide to us to
            countries outside the European Economic Area (‘EEA’) for the
            purposes of providing you with work-finding services. We will take
            steps to ensure adequate protections are in place to ensure the
            security of your information. The EEA comprises the EU member states
            plus Norway, Iceland and Liechtenstein.
          </BodyText>
          <HeaderText>3. Data retention</HeaderText>
          <BodyText>
            The Company will retain your personal data only for as long as is
            necessary. Different laws require us to keep different data for
            different periods of time.
          </BodyText>
          <BodyText>
            We must also keep your payroll records, holiday pay, sick pay and
            pensions auto-enrolment records for as long as is legally required
            by HMRC and associated national minimum wage, social security and
            tax legislation.
          </BodyText>
          <BodyText>
            Where the Company has obtained your consent to process your personal
            and sensitive personal data, we will do so in line with the above.
            Upon expiry of your consent, the Company will seek further consent
            from you. Where consent is not granted the Company will cease to
            process your personal data and sensitive personal data
          </BodyText>
          <BodyText>
            Your rights:
            <br />
            Please be aware that you have the following data protection rights:
          </BodyText>
          <ul style={{ margin: '2% 6%' }}>
            <Bullet>
              The right to be informed about the personal data the Company
              processes on you;{' '}
            </Bullet>
            <Bullet>
              The right of access to the personal data the Company processes on
              you;
            </Bullet>
            <Bullet>The right to rectification of your personal data; </Bullet>
            <Bullet>
              The right to erasure of your personal data in certain
              circumstances;{' '}
            </Bullet>
            <Bullet>
              The right to restrict processing of your personal data;
            </Bullet>
            <Bullet>
              The right to data portability in certain circumstances;{' '}
            </Bullet>
            <Bullet>
              The right to object to the processing of your personal data that
              was based on a public or legitimate interest;
            </Bullet>
            <Bullet>
              The right not to be subjected to automated decision making and
              profiling; and
            </Bullet>
            <Bullet>The right to withdraw consent at any time. </Bullet>
          </ul>
          <BodyText>
            Where you have consented to the Company processing your personal
            data and sensitive personal data you have the right to withdraw that
            consent at any time by contacting our Data Protection Officer,
            Rebecca Cook.
          </BodyText>
          <HeaderText>4. Complaints or queries</HeaderText>
          <BodyText>
            If you wish to complain about this privacy notice or any of the
            procedures set out in it please contact our Data Protection Officer,
            Rebecca Cook.
          </BodyText>
          <BodyText>
            You also have the right to raise concerns with Information
            Commissioner’s Office on 0303 123 1113 or at{' '}
            <Link>https://ico.org.uk/concerns/</Link>, or any other relevant
            supervisory authority should your personal data be processed outside
            of the UK, if you believe that your data protection rights have not
            been adhered to.
          </BodyText>
          <HeaderText>Annex A</HeaderText>
          <AnnexHeader>
            a) The lawfulness of processing conditions for personal data are
          </AnnexHeader>
          <ol style={{ margin: '2% 6%' }}>
            <Bullet>
              Consent of the individual for one or more specific purposes.
            </Bullet>
            <Bullet>
              Processing is necessary for the performance of a contract with the
              individual or in order to take steps at the request of the
              individual to enter into a contract.
            </Bullet>
            <Bullet>
              Processing is necessary for compliance with a legal obligation
              that the controller is subject to.
            </Bullet>
            <Bullet>
              Processing is necessary to protect the vital interests of the
              individual or another person.
            </Bullet>
            <Bullet>
              Processing is necessary for the performance of a task carried out
              in the public interest or in the exercise of official authority
              vested in the data controller.
            </Bullet>
            <Bullet>
              Processing is necessary for the purposes of legitimate interests
              pursued by the controller or a third party, except where such
              interests are overridden by the interests or fundamental rights or
              freedoms of the individual which require protection of personal
              data, in particular where the individual is a child.
            </Bullet>
          </ol>
          <AnnexHeader>
            b) The lawfulness of processing conditions for sensitive personal
            data are:
          </AnnexHeader>
          <ol style={{ margin: '2% 6%' }}>
            <Bullet>
              Explicit consent of the individual for one or more specified
              purposes, unless reliance on consent is prohibited by EU or Member
              State law.
            </Bullet>
            <Bullet>
              Processing is necessary for carrying out data controller’s
              obligations under employment, social security or social protection
              law, or a collective agreement, providing for appropriate
              safeguards for the fundamental rights and interests of the
              individual.
            </Bullet>
            <Bullet>
              Processing is necessary to protect the vital interests of the
              individual or another individual where the individual is
              physically or legally incapable of giving consent.
            </Bullet>
            <Bullet>
              In the course of its legitimate activities, processing is carried
              out with appropriate safeguards by a foundation, association or
              any other not-for-profit body, with a political, philosophical,
              religious or trade union aim and on condition that the processing
              relates only to members or former members (or those who have
              regular contact with it in connection with those purposes) and
              provided there is no disclosure to a third party without the
              consent of the individual.
            </Bullet>
            <Bullet>
              Processing relates to personal data which are manifestly made
              public by the individual.
            </Bullet>
            <Bullet>
              Processing is necessary for the establishment, exercise or defence
              of legal claims or whenever courts are acting in their judicial
              capacity.
            </Bullet>
            <Bullet>
              Processing is necessary for reasons of substantial public interest
              on the basis of EU or Member State law which shall be
              proportionate to the aim pursued, respects the essence of the
              right to data protection and provide for suitable and specific
              measures to safeguard the fundamental rights and interests of the
              individual.
            </Bullet>
            <Bullet>
              Processing is necessary for the purposes of preventative or
              occupational medicine, for assessing the working capacity of the
              employee, medical diagnosis, the provision of health or social
              care or treatment or the management of health or social care
              systems and services on the basis of EU or Member State law or a
              contract with a health professional and subject to the necessary
              conditions and safeguards.
            </Bullet>
            <Bullet>
              Processing is necessary for reasons of public interest in the area
              of public health, such as protecting against serious cross-border
              threats to health or ensuring high standards of quality and safety
              of healthcare and of medicinal products or medical devices, on the
              basis of EU or Member State law which provides for suitable and
              specific measures to safeguard the rights and freedoms of the
              individual, in particular professional secrecy.
            </Bullet>
            <Bullet>
              Processing is necessary for archiving purposes in the public
              interest, scientific or historical research purposes or
              statistical purposes, which shall be proportionate to the aim
              pursued, respect the essence of the right to data protection and
              provide for suitable and specific measures to safeguard
              fundamental rights and interests of the individual.
            </Bullet>
          </ol>
        </Wrapper>
      </Layout>
    )
  }
}
